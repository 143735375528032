import React, { useEffect } from 'react'
import { get_code } from '../script/animation';

const Aboutus = () => {
    useEffect(() => {
        get_code();
    }, []);
    return (
        <>
            <section className="breadcrum">
                <div className="container-fluid">
                    <div className="row">
                        <div className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                            <h1 className="text-white">About Us</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 animation-element slide-up testimonial in-view">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  animation-element slide-up testimonial in-view">
                            <div className="cms-evideo cms-evideo-5 relative">
                                <div data-background-image="background-image:url(https://7oroof.com/tfdemos/trashco/wp-content/uploads/banner-9-small.jpg);"
                                    className="cms-lazy cms-radius-trbr-16 p-tb-200" style={{ backgroundImage: "url(https://7oroof.com/tfdemos/trashco/wp-content/uploads/banner-9-small.jpg)" }}
                                >
                                    <div className="cms-btn-video layout-3 m-lr-auto cms-transition">
                                        <i className="fas fa-play ms-transition text-14  cms-icon cmsi-play" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"></i><span className="cms-text empty-none"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12  animation-element slide-up testimonial in-view">
                            <h2 className="green-color mb-4">
                                Evolution of Urban Enviro Waste Management</h2>
                            <h6 className="line-height-25 text-justify">Our company was originally incorporated as Nagpur Waste Handling Private
                                Limited in the year 2011. Subsequently, name of the Company was changed from Nagpur Waste
                                Handling Private Limited to Urban Enviro Waste Management Private Limited in the year 2022.
                                Later the company was converted from Private to Public Limited as Urban Enviro Waste Management
                                Limited.
                                <br />
                                During the Financial year 2023-24, URBAN got listed on 22nd June,2023 on National Stock Exchange
                                (SME platform). At present the shareholding pattern of the company is Promotor and Promotor
                                group (51.19%) and Public (48.81%).
                            </h6>
                            <div className="row mt-3">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-sm-3 col-3">
                                        <div className="hover-icon-bounce cursor-pointer">
                                            <img src="../assets/img/trash-collector.png" alt="" className="w-100 cms-icon width-50" />
                                        </div>
                                    </div>
                                    <h4 className="my-2">Leading the Way</h4>
                                    <h6 className="text-muted text-justify">We at Urban Enviro Waste Management Limited, strive to provide the
                                        best-in-className Waste Management Solutions & Municipal Solid Waste (MSW) Management
                                        services comprising waste collection, transportation, segregation & disposal services
                                        across the country.</h6>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-sm-3 col-3">
                                        <div className="hover-icon-bounce cursor-pointer">
                                            <img src="../assets/img/trash-collector.png" alt="" className="w-100 cms-icon width-50" />
                                        </div>
                                    </div>
                                    <h4 className="my-2">Decades of Expertise</h4>
                                    <h6 className="text-muted text-justify">The company caters to Indian Local Bodies along with providing its
                                        services to Residential Areas, Industries, Research Institutes, Power Stations,
                                        Government & Semi Government Hospitals and Cantonment Boards to name a few. The company
                                        has an established track record of being a significant player in the industry for over
                                        12 years.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4 animation-element slide-up testimonial in-view">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                            <div className="card-about p-4">
                                <div className="d-flex justify-content-center">
                                    <img src="../assets/img/mission.png" className="w-25" alt="" />
                                </div>
                                <h4 className="text-center mt-3">Our Mission
                                </h4>
                                <p className="text-justify">The mission of Urban Enviro is to provide waste management services, protect the environment, and promote recycling in order to ensure a safe and healthy community for current and future generations. To earn the trust and confidence of all stakeholders.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                            <div className="card-about-middle p-4">
                                <div className="d-flex justify-content-center">
                                    <img src="../assets/img/Vision.png" className="w-25" alt="" />
                                </div>
                                <h4 className="text-center mt-3">Our Vision

                                </h4>
                                <p className="text-justify">To become India’s leading service & solution provider in solid waste management & recycling .
                                    To transform our business from handling waste to utilizing waste as a resource while minimizing - and even eliminating - environmental impact so that both our economy and our environment can thrive.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3 animation-element slide-up testimonial in-view">
                            <div className="card-about p-4">
                                <div className="d-flex justify-content-center">
                                    <img src="../assets/img/Value.png" className="w-25" alt="" />
                                </div>
                                <h4 className="text-center mt-3">Our Value

                                </h4>
                                <p className="text-justify"> By investing in advanced technologies, you can increase the efficiency of waste management and recycling processes, reduce costs, and minimize environmental impact. This not only enhances the value of your services but also positions your company as a leader in the industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 animation-element slide-up testimonial in-view">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="height-500">
                                <div className="mb-2">
                                    <h5 className="green-color">Comprehensive Waste Management Solutions and Beyond</h5>
                                    <p className="text-justify">The company's profile includes primary and secondary collection of waste, lifting prior
                                        to transportation of it and finally disposal or treatment and/or recycling collected
                                        waste, to undertake Research and Development activities to provide revolutionary
                                        solutions to modern day hurdles. Besides this, the company also carries on the activity
                                        of providing human resource like staff, workers, skilled/semi-skilled labours required
                                        by various industries and organizations in their journey towards making our surroundings
                                        a cleaner neighbourhood.</p>
                                </div>
                                <div className="mb-2">
                                    <h5 className="green-color">Leading the Charge</h5>
                                    <p className="text-justify">The Company primarily undertakes Collection & Transport projects & MSW processing
                                        projects. Having undertaken more than 24 projects till date, of which 21 are ongoing, it
                                        has a well-established track-record of being a comprehensive service provider equipped
                                        with the resources to handle large-scale projects for local bodies as well as private
                                        players. Our portfolio of 21 ongoing projects comprises of 18 MSW C&T projects, 3 MSW
                                        processing projects.</p>
                                </div>
                                <div className="mb-2">
                                    <h5 className="green-color">Expanding Horizons</h5>
                                    <p className="text-justify">We currently extend our services in Central & Western Regions of the country and in parts
                                        of Maharashtra, Gujarat, Rajasthan & Chhattisgarh . Notable ongoing projects include
                                        Jaipur Nagar Nigam, Ankleshwar, NEERI, MIHAN India Ltd to name a few. Our commitment towards our duty can be supported by the
                                        long-standing service relationship that we were able to sustain with our customers.</p>
                                </div>
                                <div className="mb-2">
                                    <h5 className="green-color">Transforming Waste Management</h5>
                                    <p className="text-justify">The Company’s foothold in the solid waste management industry can be traced back to its
                                        incorporation in 2011, starting with a simple business of collection and transportation
                                        of waste; it has come a long way in the field of solid waste management with adoption of
                                        latest technologies and innovations thereby transforming this business into a well-oiled
                                        eco system of operations. Our policy of striving for excellence serves our customers in
                                        the rightful manner of committed & timely preservation of harmonic surroundings; this is
                                        duly reflected because our internal governance of zero tolerance for wastage in every
                                        aspect of our system.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="../assets/img/aboutus.jpg" className="d-block w-100" alt="..." />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Waste Segregation at Dumping Yard</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <iframe className="video-about" src="https://www.youtube.com/embed/eoOLEYFpXsA?si=36pozsaiv2-dbOOK"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aboutus
