import React, { useEffect, useState } from 'react'
import { BASEURL } from '../Constants';
import { admin_get_data } from '../admin/scripts/apiServices';
import PaginationControlled from './Pagination';

const Statement = () => {
  const [dataList, setDataList] = useState([])
  const [page, setpage] = useState(1)
  const itemsPerPage = 5;
  const getAllData = async () => {
    try {
      const res = await admin_get_data(`${BASEURL}/statement`);
      if (res.data) {
        setDataList(res.data.results);
      }
    } catch (error) {
      console.error("Error:", error);
      setDataList([])
    }
  };
  useEffect(() => {
    getAllData()
  }, [])

  const totalPages = Math.ceil(dataList?.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = dataList?.slice(startIndex, endIndex);
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const handlePageChange = (e, page) => {
    setpage(page)
  }
  return (
    <>
      <section class="breadcrum-shareholder">
        <div class="container-fluid">
          <div class="row">
            <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
              <h1 class="text-white">Statement of deviation or variation

              </h1>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-5 animation-element slide-up testimonial in-view">
        <div class="container">
          <div class="container">
          <div className="table-responsive mt-3 financial">
          <table className="table">
                <thead>
                <tr>
                  <th scope="col" style={{ width: "10%" }}>
                    Sr.no
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    Year
                  </th>
                  <th scope="col" style={{ width: "60%" }}>
                    Announcements
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    Attachment
                  </th>
                </tr>
                </thead>
                <tbody>
                
                  {paginatedData && paginatedData.map((item, index) => {
                    if (item.is_enable) {
                      return (<tr>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{item.year}</td>
                        <td>{item.description}</td>
                        <td>
                          {item.pdf ? (
                            <>
                              <a href={BASEURL + "/" + item.pdf} download target="_blank">
                              <img src="/assets/img/pdf (3).png" className="w-50" alt="" />
                              </a>
                            </>
                          ) : (
                            "No PDF available"
                          )}
                        </td>
                      </tr>)
                    }

                  })
                  }
                </tbody>
              </table>
              <PaginationControlled page={page} handlePageChange={handlePageChange} />
            </div>
            {/* <h1 class="text-center green-color">Coming Soon</h1>
        <h4 class="text-center">We're working hard to give you the best experience!</h4>
        <img src="../assets/img/coming.png" alt="" style={{margin:' 0 auto',display: 'flex'}} /> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default Statement
