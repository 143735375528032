import React from 'react'

const InvestorContacts = () => {
    return (
        <>
            <section class="breadcrum-shareholder">
                <div class="container-fluid">
                    <div class="row">
                        <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                            <h1 class="text-white">Investor Contacts
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="mt-5 animation-element slide-up testimonial in-view">
                <div class="container">
                    <h2 class="green-color mb-2"><i class="fas fa-user me-2"></i> CONTACT DETAILS FOR INVESTOR GRIEVANCES</h2>
                    <div class="bg-investor mt-3">
                        Company
                    </div>
                    <h5 class="my-3">Shraddha Kulkarni <br />(Company Secretary and Compliance Officer)</h5>
                    <p><i class="fas fa-map-marker-alt me-2"></i> 26, Matey Square, BPCL Petrol Pump, Gopal Nagar Road, Nagpur 440022
                    </p>
                    <p><a href="mailto:Info@Urbanenv.In" class="text-decoration-none text-dark"><i class="fas fa-envelope me-2"></i>
                        investor@urbanenv.in</a></p>
                    <p><a href="tel:7122996029" class="text-decoration-none text-dark"><i class="fas fa-phone-alt me-2"></i> 91 (712)
                        2996029 </a></p>
                    <div class="bg-investor mt-5">
                        Register and Transfer Agent
                    </div>
                    <h5 class="my-3">Bigshare Services Pvt Ltd </h5>
                    <p><i class="fas fa-map-marker-alt me-2"></i> Office No S6-2, 6th floor Pinnacle Business Park, Next to Ahura
                        Centre, Mahakali Caves Road, Andheri (East) Mumbai - 400093, India.</p>
                    <p><a href="mailto:Info@Urbanenv.In" class="text-decoration-none text-dark"><i class="fas fa-envelope me-2"></i>
                        investor@bigshareonline.com</a></p>
                    <p><a href="tel:91-22-022-62638222" class="text-decoration-none text-dark"><i
                        class="fas fa-phone-alt me-2"></i>91-22-022-62638222/00/36</a></p>
                    <p><i class="fas fa-globe-europe me-2"></i><a href="https://www.bigshareonline.com/"
                        class="text-decoration-none text-dark">www.bigshareonline.com </a> </p>
                </div>
            </section>
        </>
    )
}

export default InvestorContacts
