import React from 'react';
import { Link } from 'react-router-dom';


const Jammu = () => {
    return (
        <>
            <section>
                <img src="../assets/img/project/jammu-sub.png" class="w-100" alt="" />
            </section>
            <section class="mt-5 animation-element slide-up testimonial in-view">
                <div class="container">
                    <h1 class="green-color mb-3">Launching of Logo, Anthem, website and Yatri Apps</h1>
                    <img src="../assets/img/jammu-banner.jpeg" class="w-100" alt="" />
                    <div className='row jammnu mt-4'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center'>
                            <ul class="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active px-5 py-2" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Videos</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link px-5 py-2" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Photos</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <Link class="nav-link px-5 py-2" to='/assets/pdf/pdf.pdf' target='_blank' type="button">Brochure</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content mt-3" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="row">
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/vwLEHzhpUYU?si=YdmAgkyhexdX_t1m" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                              
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/v-6MnBXg-io?si=6qnkgNVrzV9IL-bD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/7znLmlq5MLs?si=FFl594DBaQP5oWKZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/w41R-NXRlaE?si=-dd_WmcSPnZvIpXm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/bpdy5pjRwVo?si=9MhHDQFtCRUt2Hpm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/E3lBShjpeb0?si=crmt5NCZLG2oXP77" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/kJ8NIu9plXc?si=M5nYbthunPaSLjBI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/yXo6rk9TsT8?si=OiESVg_QpPYRfIw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/yHsSMkcvxkQ?si=gmXTmm7vJ2HB6iht" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/C086o9RqeYA?si=haWAtbVrRKlj-8NL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/WCD07GS4VWo?si=C025H6wx3cvEy2na" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/ebwaV8i4QbA?si=jgimMGjogt0m-58a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/CXuKmhREuSM?si=mKe4begW5zUoKRpv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/4QnN0FL8ZzI?si=H3wDwkQT6Tk4RvR3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/-fkLyM3uqqk?si=qJX5Hbx5T54m1dnP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4'>
                            <div className='card'>
                            <iframe className='w-100' height="315" src="https://www.youtube.com/embed/Rbp_Keo5TDg?si=CrP7Ywztltx6H-jZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div class="tab-pane fade " id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-1.PNG" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-2.PNG" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-3.jpg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-4.jpg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-5.PNG" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-6.PNG" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-7.jpg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-8.jpg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-9.jpg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-10.PNG" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-11.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-12.jpg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-13.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-14.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/newpics/img-15.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-2.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-3.png" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-4.jpg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-5.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-6.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-7.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-8.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/iec-9.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/send-1.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/send-12.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/send-16.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/send-4.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/send-7.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/send-8.jpeg" className='height-300 w-100' alt="" />
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                                    <img src="../assets/img/amarnath/send-9.jpeg" className='height-300 w-100' alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Jammu
