import React from 'react'
import { Link } from 'react-router-dom';
const MaterialDocuments = () => {
  return (
    <>
          <section className="breadcrum-document">
              <div className="container-fluid">
                  <div className="row">
                      <div className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                          <h1 className="text-white">Material Documents
                          </h1>
                      </div>
                  </div>
              </div>
          </section>
          <section className="mt-5 animation-element slide-up testimonial in-view">
              <div className="container">
                  <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>01</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5><Link to="/assets/pdf/MaterialDocuments/1. AOAMOA.pdf" target='_blank' type="button" className="text-decoration-none text-dark">AOAMOA</Link></h5>
                                    
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>02</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5><Link target='_blank' to="/assets/pdf/MaterialDocuments/2. COI-02.06.2011.PDF" className="text-decoration-none text-dark">COI-02.06.2011</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>03</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/3. COI-30.03.2022.PDF" className="text-decoration-none text-dark"> COI-30.03.2022
                                  </Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>04</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/4. COI-27.12.2022.PDF" className="text-decoration-none text-dark">COI-27.12.2022</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>05</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/5. BR for IPO-20.01.2023.pdf" className="text-decoration-none text-dark">BR for IPO-20.01.2023</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>06</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/6. IPO Committee Resolution for OFS-20.01.2023.pdf" className="text-decoration-none text-dark">IPO Committee Resolution for OFS-20.01.2023</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2 ">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>07</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/7. EOGM for IPO-10.02.2023.pdf" className="text-decoration-none text-dark">EOGM for IPO-10.02.2023</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material" >
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>08</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/8. Adoption of DP by BR & IPO Committee-21.02.2023.pdf" className="text-decoration-none text-dark">Adoption of DP by BR & IPO Committee-21.02.2023</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>09</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/9.-Annual-Report-202220212020.pdf" className="text-decoration-none text-dark">Annual Report-2022,2021,2020</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>10</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/10. Restated Financial Informations.pdf" className="text-decoration-none text-dark">Restated Financial Information</Link></h5>
                              </div>
                          </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                          <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                              <div className="col-2">
                                  <div className="circle-icon">
                                      <span>11</span>
                                  </div>
                              </div>
                              <div className="col-10">
                                  <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/11. Possible Tax Benefit Certificate-17.02.2023.pdf" className="text-decoration-none text-dark">Possible Tax Benefit Certificate-17.02.2023</Link></h5>
                              </div>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-center align-items-center pt-3 icon-material">
                                  <div className="col-2">
                                      <div className="circle-icon">
                                          <span>12</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/12. All Consents.pdf" className="text-decoration-none text-dark">Consents</Link></h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>01</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/All Directors Consent.pdf" className="text-decoration-none text-dark">All Directors Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3 ">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>02</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Banker to the Comapny Consent.pdf" className="text-decoration-none text-dark">Banker to the Company Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>03</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Legal Consent.pdf" className="text-decoration-none text-dark">Legal Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>04</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/LM Consents.pdf" className="text-decoration-none text-dark"> LM Consents</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>05</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Promoters Consent.pdf" className="text-decoration-none text-dark"> Promoters Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>06</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/RTA Consent.pdf" className="text-decoration-none text-dark"> RTA Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>07</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Selling shareholder Consent.pdf" className="text-decoration-none text-dark">Selling shareholder Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3 ">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>08</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/CS-Consent.pdf" className="text-decoration-none text-dark">CS Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>09</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/CFO-Consent.pdf" className="text-decoration-none text-dark">CFO Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>10</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/CA-Consent-.pdf" className="text-decoration-none text-dark"> CA Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>11</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Banker-to-the-Offer-Consent.pdf" className="text-decoration-none text-dark">Banker to the Offer Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>12</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Senior-Managerial-Personal.pdf" className="text-decoration-none text-dark">Senior Managerial Personal</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>13</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Underwriter-consent.pdf" className="text-decoration-none text-dark">Underwriter consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>14</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Market-maker-Consent.pdf" className="text-decoration-none text-dark">Market maker Consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-2">
                              <div className="row d-flex justify-content-xl-center justify-content-lg-center justify-content-md-start justify-content-sm-start justify-content-start align-items-center pt-3 ms-xl-5 ms-lg-5 ms-lg-0 ms-sm-0 ms-0 icon-material pt-3">
                                  <div className="col-2">
                                      <div className="circle-icon-inner">
                                          <span>15</span>
                                      </div>
                                  </div>
                                  <div className="col-10">
                                      <h5 className="cursor-pointer"><Link target='_blank' to="../assets/pdf/MaterialDocuments/Syndicate-Member-consent.pdf" className="text-decoration-none text-dark">Syndicate Member consent</Link></h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
    </>
  )
}

export default MaterialDocuments
