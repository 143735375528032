import React from 'react'

const Maharashtra = () => {
    return (
        <>
            <section>
                <img src="../assets/img/maharashtra-sub.jpg" class="w-100 height-215" alt="" />
            </section>
            <section class="mt-5 animation-element slide-up testimonial in-view">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 maha mb-2 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2">
            <div class="accordion accordion-flush" id="accordionFlushExample">

            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingNineteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                   <b>Kamptee Nagar Parishad</b>
                  </button>
                </h2>
                <div id="flush-collapseNineteen" class="accordion-collapse collapse" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2 ">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Municipal Council Kamptee limits all households and commercial shops in the market area by separating wet and dry waste as well as plastic, collection by proprietor bell cart as well as tractor collection as required cleaning the market area in Kamptee for 3 Years
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headinguniqueone">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseuniqueone" aria-expanded="false" aria-controls="flush-collapseuniqueone">
                   <b>Thane Municipal Corporation</b>
                  </button>
                </h2>
                <div id="flush-collapseuniqueone" class="accordion-collapse collapse" aria-labelledby="flush-headinguniqueone" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2 ">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Daily cleaning of roads in Ovala area of Group 13 for the period of 3 years
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Daily cleaning of roads in Patlipada area of Group 15 for the period of 3 years
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headinguniquetwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseuniquetwo" aria-expanded="false" aria-controls="flush-collapseuniquetwo">
                  <b>Municipal Corporation Umred Dist-Nagpur</b>
                  </button>
                </h2>
                <div id="flush-collapseuniquetwo" class="accordion-collapse collapse" aria-labelledby="flush-headinguniquetwo" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2 ">
                    <li class="list-group-item">
                          <i class="fa fa-check"></i>Solid Waste Management, Garbage collection from door to door, Transportation and processing at site for Nagar Parishad Umred Dist.Nagpur
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingfifteen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefifteen" aria-expanded="false" aria-controls="flush-collapsefifteen">
                   <b>Municipal Corporation Chandrapur City</b>
                  </button>
                </h2>
                <div id="flush-collapsefifteen" class="accordion-collapse collapse" aria-labelledby="flush-headingfifteen" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Door to Door Collection and Transportation of segregated solid waste of Chandrapur City upto processing site directly or through collection point for 5 years period
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <b>Saoner Municipal Council</b>
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Work for Drain Cleaning, Garbage Lifting Garbage of Sweeping and Night shift sweeping at Commercial places at Saoner city Under 14 th Finance Commission for the year 2020-23.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Work for Sweeping of big and small roads at Saoner city and their increased area of Pahilapar and Sarvoday Nagar and Lifting and collection of garbage through own hand trolleys.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Work for Sweeping of big and small roads at Saoner city and their increased area of Gujjarkhedi, Vaghoda, Jatashankar Layout and Naik Layout and Lifting and collection of garbage through own hand trolleys.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Work for Processing & segregation of waste according MSW rule 2016, composting from degradable waste and scientifically disposal of non degradable waste and implement zero waste process of approx. 6 MT waste coming at trenching ground Saoner from Nagar Parishad.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Collection and Separation of wet and dry waste at the place where waste is generated from house to house and delivery to Solid Waste Management Center under 15th Finance Commission.
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingDemonew">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseDemonew" aria-expanded="false" aria-controls="flush-collapseDemonew">
                  <b> Mul Municipal Council (District Chandrapur)</b>
                  </button>
                </h2>
                <div id="flush-collapseDemonew" class="accordion-collapse collapse" aria-labelledby="flush-headingDemonew" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                    <li class="list-group-item">
                          <i class="fa fa-check"></i> Work of collection transportation and processing of solid waste cleaning of drains, transportation of collected waste from drain, maintenance of public toilets, sweeping of small and big road, night sweeping, insecticides spray in all prabhag in Municipal Council Mul area. Dist: Chandrapur
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Work of collection transportation and processing of solid waste cleaning of drains, transportation of collected waste from drain, maintenance of public toilets, sweeping of small and big road, night sweeping, insecticides spray in all prabhag in Municipal Council Mul area
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                   <b>Gadchandur Municipal Council (District Chandrapur)</b>
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i> Door to Door Collection of Solid waste with tricycle Ghantagadi and Automated Vehicle road sweeping and public toilet maintenance of Municipal Council Gadchandur
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingfour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                   <b>Bahulgaon Municipal Council (District-Yavatmal)</b>
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Collection and transportation of Solid waste management and other related work mentioned in under Babhulgaon Nagar panchyayat
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingfive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                   <b>Deori Municipal Council (District Gondia)</b>
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Daily Municipal Solid Waste collection within Deori Nagar Panchayat Jurisdiction and Transportation,Processing and Disposal to Processing site
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                   <b>Tiroda Municipal Council(District Gondia)</b>
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Daily Municipal Solid Waste collection from Prabhag No. 1 to 8 of Tirora Municipal Jurisdiction and Transportation, Processing, and Disposal to Dumping Ground at Bhivapur
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Daily Municipal Solid Waste collection within Tiroda Nagar Panchayat Jurisdiction and Transportation,Processing and Disposal to Processing site
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                   <b>National Environmental Engineering Research Institute (Nagpur)</b>
                  </button>
                </h2>
                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Implementation of Waste Management System in CSIR-NEERI, Nagpur premises.
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                   <b>Mihan India Ltd (NagpurAirport)</b>
                  </button>
                </h2>
                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Disposal of Garbage and Other Waste Material from Entire Airport Premises at Dr. Babasaheb Ambedkar International Airport,Nagpur
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingNine">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                   <b>MADC Ltd (Nagpur)</b>
                  </button>
                </h2>
                <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Disposal Of Garbage and Other Waste Material.
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTen">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                   <b>SCER Railway (Motibagh Nagpur)</b>
                  </button>
                </h2>
                <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2 ">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Collection & Transportation of waste from Motibagh Railway colonies, Itwari& Ajani Nagpurcolonies
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingEleven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                   <b>Nagpur Military Station</b>
                  </button>
                </h2>
                <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Outsourcing Conservancy Services for Nagpur Military Stationfor The Period One Year. Conservancy Staff – 28 Nos. Waste Disposal Management (other than bio degradable waste). Collection, segregation of dry and wet garbage from earmarkedarea segregation of bio-degradable and non-bio-degradable garbage.
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwenty">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                   <b>N K P Salve Institute of Medical Science & Lata Mangeshkar Hospital Nagpur</b>
                  </button>
                </h2>
                <div id="flush-collapseTwenty" class="accordion-collapse collapse" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2 ">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Garbage collection & transportation upto dumping yard.
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwelye">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelye" aria-expanded="false" aria-controls="flush-collapseTwelye">
                   <b>Hyderabad C&D Waste Pvt Ltd (RAMKY GROUP) NAGPUR</b>
                  </button>
                </h2>
                <div id="flush-collapseTwelye" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelye" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2 ">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>C&D Waste Transportation of Nagpur City.(Completed)
                      </li>
                      </ul>
                    </div>
                </div>
              </div>
          
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1 display-none">
            <img src="../assets/img/maha-1.png" class="w-100 width-50 margin-auto d-flex " alt="" />
          </div>
        </div>
      </div>   
    </section>
        </>
    )
}

export default Maharashtra
