import React, { useState,useRef,useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { BASEURL } from '../Constants';
import { ToastContainer, toast } from 'react-toastify';


const Home = () => {
  const videoRef = useRef(null);

  const handlePlay = () => {
      if (videoRef.current) {
          videoRef.current.play().catch(error => {
              console.error('Autoplay prevented:', error);
          });
      }
  };

  useEffect(() => {
      handlePlay();
  }, []);


  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const carouselControlStyles = {
    controlPrev: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '10px',
      zIndex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    controlNext: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '10px',
      zIndex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer'
    },
    icon: {
      color: 'white',
      fontSize: '20px'
    }
  };

  const initialInputs = {
    firstname: '',
    lastname: '',
    email: '',
    massage: '',
    phone_number: '',
    enquery_subject: '',
    is_request_pickup: true,
  };

  const [inputs, setInputs] = useState(initialInputs);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post(`${BASEURL}/enquery_add`, inputs);
      console.log('response: ', response);
      if (response.data.code === 200) {
        toast.success('Your request is submitted, Thank you');
        setInputs(initialInputs); // Reset the form inputs
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

 

  return (
    <>
      {/* <section>
        <video src='../../public/assets/video/VN20240705_115253.mp4' className='w-100' type="video/mp4" autoPlay></video>
      </section> */}

<div onClick={handlePlay} style={{ cursor: 'pointer' }}>
            <video ref={videoRef} autoPlay loop muted className='w-100' style={{ width: '100%' }}>
                <source src="assets/video/VN20240706_121852-new.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>

      {/* <section className="animation-element slide-up testimonial in-view">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="ban-1">
                <div className="container">
                  <div className="logo">
                    <div className="custom-loader"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div id="content1" className="">
                        <h5 className="green-color">
                          Our waste collection services are tailored for various
                          industries, offering flexible scheduling and advanced
                          technology.
                        </h5>
                        <h1 className="green-color mt-3">
                          Wastia Provides Safe And Reliable Waste Collection!
                        </h1>
                        <h5 className="green-color mt-4">
                          We have a range of waste disposal services to suit
                          your needs and get your waste removed, we have it
                          covered.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="ban-4">
                <div className="container">
                  <div className="logo">
                    <div className="custom-loader"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div id="content1" className="">
                        <h5 className="green-color">
                          We prioritize sustainability, utilizing recycling and
                          proper disposal methods in all operations
                        </h5>
                        <h1 className="green-color mt-3">
                          Competitive And Reliable Business Waste Collection!
                        </h1>
                        <h5 className="green-color mt-4">
                          Our waste collection fleet is equipped with the latest
                          tools and equipment to handle any type of waste safely
                          and efficiently.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="ban-3">
                <div className="container">
                  <div className="logo">
                    <div className="custom-loader"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                      <div id="content1" className="">
                        <h5 className="green-color">
                          With our reliable fleet and customer-centered
                          approach, we offer hassle-free waste management
                          solutions{" "}
                        </h5>
                        <h1 className="green-color mt-3">
                          Ensuring Safe and Reliable Waste Collection Every Time
                        </h1>
                        <h5 className="green-color mt-4">
                          Our customer-centric approach ensures that each client
                          receives personalized attention and support for their
                          waste collection needs.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </section> */}
      <section className="bg-green  py-5 animation-element slide-up testimonial in-view">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
              <h1 className="text-white text-left">Leading Service Provider In The Segment.</h1>
              <p className="text-white text-left">To determine the leading service provider in any segment, you'd typically need to consider various factors such as market share, customer satisfaction, innovation, reliability, and overall reputation. </p>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12 ">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-sm-12 mb-2 other">
                  <div className="card bg-white" >
                    <img src="../assets/img/pickup-truck.png" className="w-50 margin-auto width-25" alt="" />
                    <h3 className="text-center">Pickup</h3>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-sm-12 mb-2 other">
                  <div className="card bg-white" >
                    <img src="../assets/img/trash-can.png" className="w-50 margin-auto width-25" alt="" />
                    <h3 className="text-center">Collection</h3>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-sm-12 mb-2 other">
                  <div className="card bg-white" >
                    <img src="../assets/img/recycle-bin.png" className="w-50 margin-auto width-25" alt="" />
                    <h3 className="text-center">Processing</h3>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section className="third-section">
        <div className="container mt-4">
          <h2 className="green-color mb-4">Services</h2>
          <div className='desktopview-slider'>
            <Carousel
              prevIcon={<span style={carouselControlStyles.controlPrev}><i className="fas fa-chevron-left" style={carouselControlStyles.icon}></i></span>}
              nextIcon={<span style={carouselControlStyles.controlNext}><i className="fas fa-chevron-right" style={carouselControlStyles.icon}></i></span>}
            >
              <Carousel.Item>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                    <a href="/Services" className='text-decoration-none'>
                      <div className="card p-4 shadow-service">
                        <img src="../assets/img/door-to-door-1.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} alt='doortodoor' />
                        <div className="mt-3">
                          <h5 className="mt-3 mt-4 green-color">Door to Door Collection</h5>
                          <p className="text-muted">We are the best garbage door to door pickup company in India, Here we provide our services to residential sector....</p>
                          <div className="mb-1 mt-3">
                            <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                    <a href="/Services" className='text-decoration-none'>
                      <div className="card p-4 shadow-service">
                        <img src="../assets/img/road-cleaning.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} alt='road-cleaning' />
                        <div className="mt-3">
                          <h5 className="mt-3 mt-4 green-color">Road Sweeping/Cleaning</h5>
                          <p className="text-muted">Road sweeping or cleaning refers to the process of removing debris, dirt, and other unwanted materials from roads...</p>
                          <div className="mb-1 mt-3">
                            <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                    <a href="/Services" className='text-decoration-none'>
                      <div className="card p-4 shadow-service">
                        <img src="../assets/img/segregation.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} alt='segregation' />
                        <div className="mt-3">
                          <h5 className="mt-3 mt-4 green-color">Segregation</h5>
                          <p className="text-muted">Solid waste segregation is an important step in effective waste management. It helps in recycling and proper disposal of waste....</p>
                          <div className="mb-1 mt-3">
                            <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                    <a href="/Services" className='text-decoration-none'>
                      <div className="card p-4 shadow-service">
                        <img src="../assets/img/transportation.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} alt='transportation' />
                        <div className="mt-3">
                          <h5 className="mt-3 mt-4 green-color">Transportation</h5>
                          <p className="text-muted">Transportation of solid waste to a dumping yard is crucial for maintaining cleanliness and environmental health.....</p>
                          <div className="mb-1 mt-3">
                            <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                    <a href="/Services" className='text-decoration-none'>
                      <div className="card p-4 shadow-service">
                        <img src="../assets/img/recycle.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                        <div className="mt-3">
                          <h5 className="mt-3 mt-4 green-color">Processing</h5>
                          <p className="text-muted">Processing of solid municipal waste is a crucial service we offer for managing waste effectively and minimizing....</p>
                          <div className="mb-1 mt-3">
                            <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                    <a href="/Services" className='text-decoration-none'>
                      <div className="card p-4 shadow-service">
                        <img src="../assets/img/garbage (1).png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                        <div className="mt-3">
                          <h5 className="mt-3 mt-4 green-color">Bio Mining Of Legacy Waste</h5>
                          <p className="text-muted">The scientific process of excavation, treatment, segregation and gainful utilisation of aged municipal solid waste or legacy waste is ca....</p>
                          <div className="mb-1 mt-3">
                            <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className='mobileview-slider'>
            <Carousel
              prevIcon={<span style={carouselControlStyles.controlPrev}><i className="fas fa-chevron-left" style={carouselControlStyles.icon}></i></span>}
              nextIcon={<span style={carouselControlStyles.controlNext}><i className="fas fa-chevron-right" style={carouselControlStyles.icon}></i></span>}

            >
              <Carousel.Item>
                <div className="card p-4 shadow-service">
                  <img src="../assets/img/door-to-door-1.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color">Door to Door Collection</h5>
                    <p className="text-muted">We are the best garbage door to door pickup company in India, Here we provide our services to residential sector....</p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card p-4 shadow-service">
                  <img src="../assets/img/road-cleaning.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color">Road Sweeping/Cleaning</h5>
                    <p className="text-muted">Road sweeping or cleaning refers to the process of removing debris, dirt, and other unwanted materials from roads...</p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card p-4 shadow-service">
                  <img src="../assets/img/segregation.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color">Segregation</h5>
                    <p className="text-muted">Solid waste segregation is an important step in effective waste management. It helps in recycling and proper disposal of waste....</p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card p-4 shadow-service">
                  <img src="../assets/img/transportation.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color">Transportation</h5>
                    <p className="text-muted">Transportation of solid waste to a dumping yard is crucial for maintaining cleanliness and environmental health.....</p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card p-4 shadow-service">
                  <img src="../assets/img/recycle.png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color">Processing</h5>
                    <p className="text-muted">Processing of solid municipal waste is a crucial service we offer for managing waste effectively and minimizing....</p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card p-4 shadow-service">
                  <img src="../assets/img/garbage (1).png" width="40%" className="pt-3" style={{ margin: '0 auto' }} />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color">Bio Mining Of Legacy Waste</h5>
                    <p className="text-muted">The scientific process of excavation, treatment, segregation and gainful utilisation of aged municipal solid waste or legacy waste is ca....</p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="/Services" role="button">Know More<i className="fas fa-arrow-right px-2"></i></a>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>

        </div>
      </section>
      <section class="bg_section py-5 mt-5 animation-element slide-up testimonial in-view">
        <div class="container">
          <div class="display-grid">
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img src="../assets/img/quality.png" class="w-100 width-25" alt="" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h4 class="text-white">13+</h4>
                    <p class="">Years of Experience</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img src="../assets/img/truck.png" class="w-100 width-25" alt="" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h4 class="text-white">2,000+</h4>
                    <p class="">Tonns of Waste
                      Handling
                      Everyday
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img src="../assets/img/house.png" class="w-100 width-25" alt="" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h4 class="text-white">6.30 Lakhs (approx)</h4>
                    <p class="">Household & other Establishment served Everyday
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img src="../assets/img/investment.png" class="w-100 width-25" alt="" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h4 class="text-white">5+</h4>
                    <p class="">States where project Executed</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img src="../assets/img/project.png" class="w-100 width-25" alt="" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h4 class="text-white">30+</h4>
                    <p class="">Completed
                      & Ongoing
                      Projects
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-5 animation-element slide-up testimonial in-view">
        <div class="container">
          <h2 class=" mb-4"><span class="green-color">Key Operational Highlights</span><span class="ms-2 font-18">(As of March 2024)</span></h2>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 service-block-one mb-3">
              <div class="inner-box">
                <figure class="image-box"><a><img src="../assets/img/key-1.jpg" alt="" /></a></figure>
                <div class="lower-content">
                  <div class="icon-box">
                    <div class="icon-bg" style={{ backgroundImage: "url('../assets/img/icon-bg-1.png')" }}></div>
                    <div class="icon"><i class="fas fa-clipboard-list"></i></div>
                  </div>
                  <h3 class="text-center">mmt</h3>
                  <p class="text-center">Waste Processed since inception of the project</p>

                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 service-block-one mb-3">
              <div class="inner-box">
                <figure class="image-box"><a><img src="../assets/img/key-2.jpg" alt="" /></a></figure>
                <div class="lower-content">
                  <div class="icon-box">
                    <div class="icon-bg" style={{ backgroundImage: "url('../assets/img/icon-bg-1.png')" }}></div>
                    <div class="icon"><i class="fas fa-truck-pickup"></i></div>

                  </div>
                  <h3 class="text-center">600</h3>
                  <p class="text-center">Specialised Vehicles</p>

                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 service-block-one mb-3">
              <div class="inner-box">
                <figure class="image-box"><a><img src="../assets/img/key-3.jpg" alt="" /></a></figure>
                <div class="lower-content">
                  <div class="icon-box">
                    <div class="icon-bg" style={{ backgroundImage: "url('../assets/img/icon-bg-1.png')" }}></div>
                    <div class="icon"><i class="fas fa-users"></i></div>
                  </div>
                  <h3 class="text-center">2790</h3>
                  <p class="text-center">Full-time Employees</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-sectionnew mt-5 animation-element slide-up testimonial in-view">
        <h2 class="text-center mb-5"><span class="text-white">Our Strenghts</span></h2>
        <div class="container">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img src="../assets/img/news/authorize.png" class="w-100 width-75" alt="" />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">Authorized end destinations for all waste streams</h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img src="../assets/img/news/complexity.png" class="w-100 width-75" alt="" />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">Data and technology driven processes ensuring transparency</h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img src="../assets/img/news/document.png" class="w-100 width-75" alt="" />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">Compliant with all laws on labour & waste management</h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12  mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img src="../assets/img/news/professional-services.png" class="w-100 width-75" alt="" />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">Professional & diverse core team</h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12  mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img src="../assets/img/news/mentorship.png" class="w-100 width-75" alt="" />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">Dignified & safe careers that have uplifted the socio-economic status of our field team</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="third-section">
        <div className="container mt-4">
          <h2 className="green-color mb-4">Services</h2>

          <div className="container">
            <Slider {...settings} className="service-slider">
              <div className="px-2 mb-3">
                <div className="card p-4 shadow-service">
                  <img
                    src="../assets/img/door-to-door-1.png"
                    width="40%"
                    className="pt-3"
                    style={{ margin: "0 auto" }}
                    alt="Door to Door Collection"
                  />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color one-line">Door to Door Collection</h5>
                    <p className="text-muted four-line">
                      We are the best garbage door to door pickup company in India, Here we provide our services to residential sector....
                    </p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="./Services.html" role="button">
                        Know More<i className="fas fa-arrow-right px-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 mb-3">
                <div className="card p-4 shadow-service">
                  <img
                    src="../assets/img/road-cleaning.png"
                    width="40%"
                    className="pt-3"
                    style={{ margin: "0 auto" }}
                    alt="Road Sweeping/Cleaning"
                  />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color one-line">Road Sweeping/Cleaning</h5>
                    <p className="text-muted four-line">
                      Road sweeping or cleaning refers to the process of removing debris, dirt, and other unwanted materials from roads...
                    </p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="./Services.html" role="button">
                        Know More<i className="fas fa-arrow-right px-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 mb-3">
                <div className="card p-4 shadow-service">
                  <img
                    src="../assets/img/segregation.png"
                    width="40%"
                    className="pt-3"
                    style={{ margin: "0 auto" }}
                    alt="Segregation"
                  />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color one-line">Segregation</h5>
                    <p className="text-muted four-line">
                      Solid waste segregation is an important step in effective waste management. It helps in recycling and proper disposal of waste....
                    </p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="./Services.html" role="button">
                        Know More<i className="fas fa-arrow-right px-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 mb-3">
                <div className="card p-4 shadow-service">
                  <img
                    src="../assets/img/transportation.png"
                    width="40%"
                    className="pt-3"
                    style={{ margin: "0 auto" }}
                    alt="Transportation"
                  />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color one-line">Transportation</h5>
                    <p className="text-muted four-line">
                      Transportation of solid waste to a dumping yard is crucial for maintaining cleanliness and environmental health.....
                    </p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="./Services.html" role="button">
                        Know More<i className="fas fa-arrow-right px-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 mb-3">
                <div className="card p-4 shadow-service">
                  <img
                    src="../assets/img/recycle.png"
                    width="40%"
                    className="pt-3"
                    style={{ margin: "0 auto" }}
                    alt="Processing"
                  />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color one-line">Processing</h5>
                    <p className="text-muted four-line">
                      Processing of solid municipal waste is a crucial service we offer for managing waste effectively and minimizing....
                    </p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="./Services.html" role="button">
                        Know More<i className="fas fa-arrow-right px-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 mb-3">
                <div className="card p-4 shadow-service">
                  <img
                    src="../assets/img/garbage (1).png"
                    width="40%"
                    className="pt-3"
                    style={{ margin: "0 auto" }}
                    alt="Bio Mining Of Legacy Waste"
                  />
                  <div className="mt-3">
                    <h5 className="mt-3 mt-4 green-color one-line">Bio Mining Of Legacy Waste</h5>
                    <p className="text-muted four-line">
                      The scientific process of excavation, treatment, segregation and gainful utilisation of aged municipal solid waste or legacy waste is ca....
                    </p>
                    <div className="mb-1 mt-3">
                      <a className="btn service-btn2" href="./Services.html" role="button">
                        Know More<i className="fas fa-arrow-right px-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section> 
       <section class="bg_section py-5 mt-5 animation-element slide-up testimonial in-view">
        <div class="container">
          <div class="display-grid">
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img
                      src="../assets/img/quality.png"
                      class="w-100 width-25"
                      alt=""
                    />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h3 class="text-white">13+</h3>
                    <p class="">Years of Experience</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img
                      src="../assets/img/truck.png"
                      class="w-100 width-25"
                      alt=""
                    />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h3 class="text-white">2,000+</h3>
                    <p class="">Tonns of Waste Handling Everyday</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img
                      src="../assets/img/house.png"
                      class="w-100 width-25"
                      alt=""
                    />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h3 class="text-white">Mn</h3>
                    <p class="">
                      Household & other Establishment served Everyday
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img
                      src="../assets/img/investment.png"
                      class="w-100 width-25"
                      alt=""
                    />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h3 class="text-white">5+</h3>
                    <p class="">States where project Executed</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-1">
              <div class="card bg-green-color border-0 p-3 ">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                    <img
                      src="../assets/img/project.png"
                      class="w-100 width-25"
                      alt=""
                    />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                    <h3 class="text-white">30+</h3>
                    <p class="">Completed & Ongoing Projects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
       <section class="mt-5 animation-element slide-up testimonial in-view">
        <div class="container">
          <h2 class=" mb-4">
            <span class="green-color">Key Operational Highlights</span>
            <span class="ms-2 font-18">(As of March 2024)</span>
          </h2>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 service-block-one mb-2">
              <div class="inner-box">
                <figure class="image-box">
                  <a>
                    <img src="../assets/img/key-1.jpg" alt="" />
                  </a>
                </figure>
                <div class="lower-content">
                  <div class="icon-box">
                    <div
                      class="icon-bg"
                      style={{
                        backgroundImage: "url('../assets/img/icon-bg-1.png')",
                      }}
                    ></div>
                    <div class="icon">
                      <i class="fas fa-clipboard-list"></i>
                    </div>
                  </div>
                  <h3 class="text-center">mmt</h3>
                  <p class="text-center">
                    Waste Processed since inception of the project
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 service-block-one mb-2">
              <div class="inner-box">
                <figure class="image-box">
                  <a>
                    <img src="../assets/img/key-2.jpg" alt="" />
                  </a>
                </figure>
                <div class="lower-content">
                  <div class="icon-box">
                    <div
                      class="icon-bg"
                      style={{
                        backgroundImage: "url('../assets/img/icon-bg-1.png')",
                      }}
                    ></div>
                    <div class="icon">
                      <i class="fas fa-truck-pickup"></i>
                    </div>
                  </div>
                  <h3 class="text-center">600</h3>
                  <p class="text-center">Specialised Vehicles</p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 service-block-one mb-2">
              <div class="inner-box">
                <figure class="image-box">
                  <a>
                    <img src="../assets/img/key-3.jpg" alt="" />
                  </a>
                </figure>
                <div class="lower-content">
                  <div class="icon-box">
                    <div
                      class="icon-bg"
                      style={{
                        backgroundImage: "url('../assets/img/icon-bg-1.png')",
                      }}
                    ></div>
                    <div class="icon">
                      <i class="fas fa-users"></i>
                    </div>
                  </div>
                  <h3 class="text-center">2790</h3>
                  <p class="text-center">Full-time Employees</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
       <section class="bg-sectionnew mt-5 animation-element slide-up testimonial in-view">
        <h2 class="text-center mb-5">
          <span class="text-white">Our Strenghts</span>
        </h2>
        <div class="container">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img
                        src="../assets/img/news/authorize.png"
                        class="w-100 width-75"
                        alt=""
                      />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">
                        Authorized end destinations for all waste streams
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img
                        src="../assets/img/news/complexity.png"
                        class="w-100 width-75"
                        alt=""
                      />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">
                        Data and technology driven processes ensuring
                        transparency
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img
                        src="../assets/img/news/document.png"
                        class="w-100 width-75"
                        alt=""
                      />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">
                        Compliant with all laws on labour & waste management
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12  mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img
                        src="../assets/img/news/professional-services.png"
                        class="w-100 width-75"
                        alt=""
                      />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">
                        Professional & diverse core team
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12  mb-5">
                  <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-3">
                      <img
                        src="../assets/img/news/mentorship.png"
                        class="w-100 width-75"
                        alt=""
                      />
                    </div>
                    <div class="col-xl-9 col-lg-9 col-md-8 col-ms-9 col-9">
                      <h5 class="text-white">
                        Dignified & safe careers that have uplifted the
                        socio-economic status of our field team
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section class="mt-5  animation-element slide-up testimonial in-view">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 contact">
              <div class="card">
                <div class="card-header">
                  <h3 class="text-white">Request Pickup</h3>
                </div>
                <div class="card-body">
                  <form action="" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                        <label htmlFor="firstname" className="form-label text-dark font-18">
                          First Name*
                        </label>
                        <input
                          onChange={handleChange}
                          name="firstname"
                          value={inputs.firstname}
                          type="text"
                          className="form-control"
                          id="firstname"
                          required
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                        <label htmlFor="lastname" className="form-label text-dark font-18">
                          Last Name*
                        </label>
                        <input
                          onChange={handleChange}
                          name="lastname"
                          value={inputs.lastname}
                          type="text"
                          required
                          className="form-control"
                          id="lastname"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                        <label htmlFor="email" className="form-label text-dark font-18">
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          className="form-control"
                          id="email"
                          required
                          aria-describedby="emailHelp"
                          value={inputs.email}
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                        <label htmlFor="enquery_subject" className="form-label text-dark font-18">
                          Enquiry Subject*
                        </label>
                        <input
                          type="text"
                          name="enquery_subject"
                          className="form-control"
                          id="enquery_subject"
                          aria-describedby="emailHelp"
                          required
                          value={inputs.enquery_subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                        <label htmlFor="phone" className="form-label text-dark font-18">
                          Phone Number*
                        </label>
                        <input
                          type="tel"
                          id="phone"
                          name="phone_number"
                          pattern="[0-9]{10}"
                          maxLength={10}
                          className="form-control p-3"
                          placeholder="Phone Number*"
                          value={inputs.phone_number}
                          onChange={handleChange}
                          required
                          title="Please enter exactly 10 digits"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                        <label htmlFor="massage" className="form-label text-dark font-18">
                          Message*
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="massage"
                          aria-describedby="emailHelp"
                          name="massage"
                          onChange={handleChange}
                          required
                          value={inputs.massage}
                        ></textarea>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3">
                        <button className="contact_us btn px-3 py-2 text-center d-flex" type="submit">
                          {loading && (
                            <div className="spinner-overlay ">
                              <Spinner animation="border" size="sm" role="status" className="spinner">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </div>
                          )}
                          {loading ? ' please wait...' : 'Request Call Back'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div class="content-left-contact p-3">
                <h3 class="text-white">
                  Wastia Provide Safe, Trusted And Reliable Waste Collection!
                </h3>

                <div class="row mb-3 mt-3">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                    <div class="hover-icon-bounce cursor-pointer">
                      <img
                        src="../assets/img/trash-bin.png"
                        alt=""
                        class="w-100"
                      />
                    </div>
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <h5 class="text-white font-18">Great Service & Low Cost</h5>
                    <p class="text-white">
                      If your business is looking for a reliable, cost effective
                      general waste collection then you should cgoose us now!
                    </p>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                    <img src="../assets/img/garbage.png" alt="" class="w-100" />
                  </div>
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                    <h5 class="text-white font-18">Carbon Trust Certified</h5>
                    <p class="text-white">
                      We will work with you to treat your trash in the best
                      possible way for environment and to save our beloved
                      planet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <section className="mt-5 animation-element slide-up testimonial in-view">
        <div className="container">
          <h2 className="green-color mb-3">Ongoing Projects</h2>
          <div className="container pt-5 pb-4">
            <div className="row">
              <div className="owl-slider">
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <div className="mb-4">
                      <div className="mx-2">
                        <div className="project-block-one">
                          <div className="inner-box">
                            <figure className="image-box">
                              <img src="../assets/img/gujrat-1.jpg" alt="Gujarat" />
                            </figure>
                            <div className="content-box">
                              <div className="view-btn">
                                <a href="/Gujarat" className="lightbox-image" data-fancybox="gallery">
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div>
                              <div className="text-box">
                                <p>Gujarat</p>
                                <h3>Door To Door Refuse Garbage Collection And Transportation</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="mb-4">
                      <div className="mx-2">
                        <div className="project-block-one">
                          <div className="inner-box">
                            <figure className="image-box">
                              <img src="../assets/img/rajasthan-1.jpg" alt="Rajasthan" />
                            </figure>
                            <div className="content-box">
                              <div className="view-btn">
                                <a href="/Rajasthan" className="lightbox-image" data-fancybox="gallery">
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div>
                              <div className="text-box">
                                <p>Rajasthan</p>
                                <h3>Providing No. of 50 Vehicles (auto-tippers) for Door to Door..</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="mb-4">
                      <div className="mx-2">
                        <div className="project-block-one">
                          <div className="inner-box">
                            <figure className="image-box">
                              <img src="../assets/img/madhyapradesh-1.jpg" alt="Madhya Pradesh" />
                            </figure>
                            <div className="content-box">
                              <div className="view-btn">
                                <a href="/Madhyapradesh" className="lightbox-image" data-fancybox="gallery">
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div>
                              <div className="text-box">
                                <p>Madhya Pradesh</p>
                                <h3>Cleaning and Upkeeping of roads, view point, sewerage system..</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="mb-4">
                      <div className="mx-2">
                        <div className="project-block-one">
                          <div className="inner-box">
                            <figure className="image-box">
                              <img src="../assets/img/chhattisgarh.png" alt="Chhattisgarh" />
                            </figure>
                            <div className="content-box">
                              <div className="view-btn">
                                <a href="/Chhattisgarh" className="lightbox-image" data-fancybox="gallery">
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div>
                              <div className="text-box">
                                <p>Chhattisgarh</p>
                                <h3>Cleaning and Upkeeping of roads, view point, sewerage system..</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="mb-4">
                      <div className="mx-2">
                        <div className="project-block-one">
                          <div className="inner-box">
                            <figure className="image-box">
                              <img src="../assets/img/maharashtra-1.jpg" alt="Maharashtra" />
                            </figure>
                            <div className="content-box">
                              <div className="view-btn">
                                <a href="/Maharashtra" className="lightbox-image" data-fancybox="gallery">
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div>
                              <div className="text-box">
                                <p>Maharashtra</p>
                                <h3>Work for Drain Cleaning, Garbage Lifting Garbage..</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="mb-4">
                      <div className="mx-2">
                        <div className="project-block-one">
                          <div className="inner-box">
                            <figure className="image-box">
                              <img src="../assets/img/jammu-1.png" alt="Maharashtra" />
                            </figure>
                            <div className="content-box">
                              <div className="view-btn">
                                <a href="/Jammu" className="lightbox-image" data-fancybox="gallery">
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div>
                              <div className="text-box">
                                <p>Jammu And Kashmir</p>
                                <h3>Project of Collection, transportation, processing..</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
