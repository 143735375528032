import React from 'react'

const DetailsKMP = () => {
  return (
    <>
        <section class="breadcrum-shareholder">
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
          <h1 class="text-white">Details of KMP for determining materiality of event or
          information
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container">
      <div class="container">
        <h1 class="text-center green-color">Coming Soon</h1>
        <h4 class="text-center">We're working hard to give you the best experience!</h4>
        <img src="../assets/img/coming.png" alt="" style={{margin:' 0 auto',display: 'flex'}} />
      </div>
    </div>
  </section>

    </>
  )
}

export default DetailsKMP
