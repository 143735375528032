import React from 'react'

const SureshSharma = () => {
  return (
    <>
      <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container mt-4">
      <div class="row justify-content-center align-items-start">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 animation-element slide-up testimonial in-view">
          <img src="../assets/img/SureshSharma.png" classNameName='w-100' alt="" />
          <h1 class="blue-color text-center mt-4">Shri Suresh Sharma</h1>
          <h3 class="text-center">Whole Time Director</h3>
          <h5 class="text-justify text-muted mt-3">He is a Whole Time Director and Promoter of the Company. He has
            completed his Secondary Education. He has approximately 15 years of work experience in waste management
            industry. He is instrumental in the growth of the Management of the Project, Planning, Execution and
            business operations of our company.</h5>
        </div>
      </div>
    </div>
  </section>  
    </>
  )
}

export default SureshSharma
