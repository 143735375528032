import React from 'react'
import { Link } from 'react-router-dom';

const MaterialContracts = () => {
  return (
    <>
        <section class="breadcrum-document">
        <div class="container-fluid">
            <div class="row">
                <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                    <h1 class="text-white">Material Contracts 
                    </h1>
                </div>
            </div>
        </div>
    </section>
    <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container">
      <div class="row d-flex justify-content-center pt-3">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/1.-Offer-Agreement-20.02.2023.pdf"  class="text-decoration-none">
              1. Offer Agreement- 20.02.2023
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/2.-Registrar-Agreement-10.02.2023.pdf"  class="text-decoration-none">
              2. Registrar Agreement- 10.02.2023
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/3.-Underwriting-Agreement-23.05.2023.pdf" class="text-decoration-none">
              3. Underwriting Agreement-23.05.2023
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/4.-Market-Making-Agreement-19.05.2023.pdf" class="text-decoration-none">
              4. Market Making Agreement -19.05.2023
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/5.-Banker-to-the-offer-Agreement-15.05.2023.pdf" class="text-decoration-none">
              5. Banker to the offer Agreement- 15.05.2023
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/6.-Share-Escrow-Agreement-13.03.2023 (4).pdf" class="text-decoration-none">
              6. Share Escrow Agreement- 13.03.2023
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/7.-Syndicate-Agreement-23.05.2023 (1).pdf" class="text-decoration-none">
              7. Syndicate Agreement- 23.05.2023
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/7.-Tripartite-agreement-with-NSDL-14.11.2022 (1).pdf" class="text-decoration-none">
              8. Tripartite agreement with NSDL- 14.11.2022
            </Link></h5>
        </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="box-highlight mb-3">
            <h5 class="ms-2 mb-0"><Link target='_blank' to="../assets/pdf/MaterialContracts/8.-Tripartite-agreement-with-CDSL-09.11.2022.pdf" class="text-decoration-none">
              9. Tripartite agreement with CDSL- 09.11.2022
            </Link></h5>
        </div>
        </div>
        </div>
    </div>
  </section>
    </>
  )
}

export default MaterialContracts
