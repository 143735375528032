import React from 'react'

const Chhattisgarh = () => {
    return (
        <>
            <section>
                <img src="../assets/img/chhattisgarh-sub.png" class="w-100 height-215" alt="" />
            </section>
            <section class="mt-5 animation-element slide-up testimonial in-view">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 maha mb-2">
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingOne">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <b>Bhilai Nagar Palika Nigam</b>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body maharashtra">
                                            <ul class="list-group list-group-borderless mt-2">
                                                <li class="list-group-item">
                                                    <i class="fa fa-check"></i>Working in all five zone, 52.5 wards of Nagar Palik Nigam Bhilai for Door-to-Door garbage collection, Drain Cleaning, Mechanized & Manual Sweeping, Lifting and Transportation of waste upto Jamul SWM site Bhilai. Handling 120 TPD waste with the team of 1600 plus Employees
                                                </li>
                                                <li class="list-group-item">
                                                    <i class="fa fa-check"></i>Working in Nine SLRM centers which is located in all Five Zones of BMC for segregation for Waste (Dry & Wet) and making Compost from Wet Waste & Dry wet Waste (like Iron, Cardboards, Plastic, Paper, Tyre, Shoe, Clothes & other recycles item).
                                                </li>
                                             
                                            </ul>

                                        </div>
                                    </div>
                                </div>  
                              
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            <b>Monitoring System at Bhilai</b>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body maharashtra">
                                            <ul class="list-group list-group-borderless mt-2">
                                                <li class="list-group-item">
                                                    <i class="fa fa-check"></i>GPS has been installed on all compactors and dumper placer vehicles used for transportation of waste to the landfill. This helps in real time monitoring of these vehicles and resulting in improved efficiency.
                                                </li>
                                                <li class="list-group-item">
                                                    <i class="fa fa-check"></i>Biometric Face Reader Attendance and Mobile Selfie attendance system.
                                                </li>
                                                <li class="list-group-item">
                                                    <i class="fa fa-check"></i>Customized software has been developed to maintain vehicle repair and maintenance records.
                                                </li>
                                             
                                            </ul>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 display-none">
                            <img src="../assets/img/new-img-5.png" class="w-75" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Chhattisgarh
