import React from 'react'
import { Link } from 'react-router-dom';
const Policies = () => {
  return (
    <>
        <section class="breadcrum-shareholder">
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
          <h1 class="text-white">Policies 
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container">
      <div class="table-responsive mt-3 financial">
        <table cla>
          <thead>
            <tr>
              <th scope="col" style={{width:"10%"}}>Sr No</th>
              <th scope="col" style={{width:"80%"}}>Policies</th>
              <th scope="col" style={{width:"10%"}}>PDF</th>
            </tr>
          </thead>
          <tbody>
             <tr>
              <td>1</td>
              <td>Investor Grievance Redressal Policy</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/Investor Grievance Redressal Policy.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt=""/></Link></td>
            </tr> 
             <tr>
              <td>2</td>
              <td>Policy for Determination of Materiality of Events or Information</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/Policy for Determination of  Materiality of Events or Information.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt=""/></Link></td>
            </tr> 
             <tr>
              <td>3</td>
              <td>Code of Fair Disclosure and Code of Conduct for Prevention of Insider Trading, 2015</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/Code of Fair Disclosure and Code of Conduct for Prevention of Insider Trading, 2015.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt=""/></Link></td>
            </tr> 
             <tr>
              <td>4</td>
              <td>Code Of Business Conduct and Ethics for Board Members and Senior Management Personnel</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/CODE OF BUSINESS CONDUCT AND ETHICS FOR BOARD MEMBERS AND SENIOR MANAGEMENT PERSONNEL.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt=""/></Link></td>
            </tr> 
             <tr>
              <td>5</td>
              <td>Related Party Transaction Policy</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/Related party transactions policy.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt="" /></Link></td>
            </tr> 
             <tr>
              <td>6</td>
              <td>Vigil Mechanism / Whistle Blower Policy</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/Vigi Mechanism -Whistle Blower Policy.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt=""/></Link></td>
            </tr> 
             <tr>
              <td>7</td>
              <td>Prevention of Sexual Harassment Policy</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/Prevention of Sexual Harassment Policy.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt=""/></Link></td>
            </tr> 
             <tr>
              <td>8</td>
              <td>Dividend Policy</td>
              <td><Link target='_blank' to="../assets/pdf/Policies/Dividend-Distribution-Policy.pdf"><img src="../assets/img/pdf.png"  class="w-50" alt=""/></Link></td>
            </tr> 
            
          </tbody>
        </table>
      </div>
    </div>
  </section>
    </>
  )
}

export default Policies
