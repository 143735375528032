import React from 'react'

const VikassharmaNew = () => {
  return (
    <>
       <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container mt-4">
      <div class="row justify-content-center align-items-start">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 animation-element slide-up testimonial in-view">
          <img src="../assets/img/VikasSharma.png" classNameName='w-100' alt="" />
          <h1 class="blue-color text-center mt-4">Shri Vikas Sharma</h1>
          <h3 class="text-center">Executive Director</h3>
          <h5 class="text-justify text-muted mt-3">He is an Executive Director of the Company. He is LLB Graduate from
            University of Rajasthan in the year 2005. He has approximately 17 years of work experience in waste
            management industry. He is instrumental in the growth of our business and has developed vibrant relations
            with our customers. Under his leadership our company has been successful in develop business operations.
          </h5>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default VikassharmaNew
