import React, { useState } from "react";
import "./styles.css";

import Pagination from "@mui/material/Pagination";

export default function PaginationControlled({ count, page, bgColor, handlePageChange }) {
  return (
    <div className="pagination-div">
      <Pagination
        sx={{
          "& .MuiPaginationItem-text": {
            color: bgColor === 'admin' ? "#3bc0c3 !important" : "#84b346!important",
            border: "1px solid var(--grey)",
          },
          "& .MuiPaginationItem-text:hover": {
            backgroundColor: "#ffffff!important",
            color: bgColor === 'admin' ? "#3bc0c3 !important" : "#84b346!important",
          },
          "& .Mui-selected  ": {
            backgroundColor: bgColor === 'admin' ? "#3bc0c3 !important" : "#84b346!important",
            color: "#ffffff !important",
          },
          "& .MuiPaginationItem-ellipsis": {
            border: "none",
          },
        }}
        count={count}
        page={page}
        onChange={handlePageChange}
      />
    </div>
  );
}