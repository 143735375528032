import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { admin_get_data } from "../admin/scripts/apiServices";
import PaginationControlled from "./Pagination";
import { BASEURL } from "../Constants";

const AnnualReturn = () => {
  const [dataList, setDataList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (event, value) => {
    setPage(value); 
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    setSearchedList(dataList);
  }, [dataList]);

  useEffect(() => {
    if (searchValue) {
      searchListData();
    } else {
      setSearchedList(dataList);
    }
  }, [searchValue, dataList]);

  const getAllData = async () => {
    try {
      const res = await admin_get_data(`${BASEURL}/annual_reports`);
      if (res.data) {
        const enabledData = res.data.results.filter((item) => item.annual_is_enable === true);
        setDataList(enabledData);
      } else {
        console.log("code not accepted at backend");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const searchListData = () => {
    const filteredList = dataList.filter((item) => {
      return item.year && item.year.toString().includes(searchValue);
    });
    setSearchedList(filteredList);
    setPage(1); // Reset to first page on new search
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2000;
    const years = [];
    for (let i = currentYear; i >= startYear; i--) {
      years.push(i);
    }
    return years;
  };

  const handleYearChange = (event) => {
    setSearchValue(event.target.value);
  };

  const currentData = searchedList.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <> 
      <section className="breadcrum-Announcement">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
              <h1 className="text-white">Annual Reports</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 animation-element slide-up testimonial in-view">
        <div className="container">
          <div className="row d-flex align-items-center mb-2">
            <div className="col-xl-1 col-lg-2 col-md-2 col-sm-4 col-4">
              <label
                htmlFor="inputState"
                className="form-label font-18 font-500 mb-0"
              >
                Select&nbsp;Year:
              </label>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 select">
              <select className="form-control w-100 form-select"
                onChange={handleYearChange}
                value={searchValue}
              >
                <option value="" >
                  All
                </option>
                {generateYearOptions().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="table-responsive mt-3 financial">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "10%" }}>
                  Sr.no
                  </th>
                  <th scope="col" style={{ width: "20%" }}>
                    Year
                  </th>
                  <th scope="col" style={{ width: "60%" }}>
                    Announcements
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    Attachment
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentData.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data found
                    </td>
                  </tr>
                ) : (
                  currentData.map((item, index) => (
                    <tr key={index}>
                      <td>{(page - 1) * itemsPerPage + index + 1}</td>
                      <td>{item.annual_year}</td>
                      <td>{item.annual_description}</td>
                      <td>
                        {item.annual_pdf ? (
                          <>
                            
                            
                            <a
                              href={`${process.env.REACT_APP_API_BASE_URL}/${item.annual_pdf}`}
                            
                              target="_blank"
                            >
                              <img src="/assets/img/pdf (3).png" className="w-50" alt="" />
                            </a>
                          </>
                        ) : (
                          "No PDF available"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <PaginationControlled
          page={page}
          count={Math.ceil(searchedList.length / itemsPerPage)}
          handlePageChange={handlePageChange}
        />
      </section>
    </>
  );
};

export default AnnualReturn;
