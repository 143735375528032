import React from 'react'

const Disclosures = () => {
  return (
    <>
      <section class="breadcrum-shareholder">
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
          <h1 class="text-white">Disclosures under Regulation 46 and
            47 of SEBI (LODR) Regulations, 2015
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
          <div class="box-disclou mt-3"><a href="/About-us"> 1. Details of Business</a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/2.Terms-and-Conditions-of-appointment-of-Independent-Directors.pdf" target='_blank'> 2. Terms and Conditions of appointment of the Independent
              Directors</a></div>
          <div class="box-disclou mt-3"><a href="/BoardCommittees">3. Composition of Committees</a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/Code-of-Business-Conduct-and-Ethics-of-Board-Members-and-Senior-Management-Personnel.pdf" target='_blank'>4. Code of Conduct of Board of Directors and SMP </a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/Vigil-Whistle-Blower-Mechanism.pdf" target='_blank'>5. Vigil Mechanism/ Whistle-Blower Policy </a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/Criteria-of-making-payment-to-Non-Executive-Directors.pdf" target='_blank'>6. Criteria of making payments to non-executive directors</a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/Related-party-transactions-policy.pdf" target='_blank'>7. Policy on Materiality of Related Party Transactions and on
              Dealing with Related Party Transaction </a></div>
          <div class="box-disclou mt-3"><a href="#">8. Policy for determining Material Subsidiaries-Nil</a></div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/Familiarization-program-for-ID.pdf" target='_blank'>9. Familiarisation Programme for Independent Directors</a></div>
          <div class="box-disclou mt-3"><a href="/InvestorContacts">10. Investor Contacts </a></div>
          <div class="box-disclou mt-3"><a href="/NoticeofBoardMeeting">11. Notice of Board Meeting </a></div>
          <div class="box-disclou mt-3"><a href="/NoticesrelatedtoAGM">12. Notices related to AGM / EGM </a></div>
          <div class="box-disclou mt-3"><a href="/FinancialsReports">13. Financial Reports</a></div>
          <div class="box-disclou mt-3"><a href="/AnnualReturn">14. Annual Reports</a></div>
          <div class="box-disclou mt-3"><a href="/ShareholdingPattern">15. Shareholding Pattern</a></div>
          <div class="box-disclou mt-3"><a href="#">16. Details of agreements entered into with the media companies -N.A </a>
          </div>
        </div>
      </div>    
      <div class="row d-flex justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        
          <div class="box-disclou mt-3"><a href="#">17. Analyst / Institutional investor meet-Nil</a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/New-Name-And-The-Old-Name-Of-The-Listed-Entity-For-A-Continuous-Period-Of-One-Year.pdf" target='_blank'>18. New name and the old name of the listed entity </a></div>
          <div class="box-disclou mt-3"><a href="#">19. All credit ratings obtained by the entity for all its
              outstanding instruments-N.A</a></div>
          <div class="box-disclou mt-3"><a href="#">20. Credit Ratings </a></div>
          <div class="box-disclou mt-3"><a href="Noticestohareholders">21. Notices to shareholders </a></div>
          <div class="box-disclou mt-3"><a href="#">22. Financial Statements of Subsidiary- N.A</a></div>
          {/* <div class="box-disclou mt-3"><a href="Secretarial.html">23. Secretarial Compliance Report</a></div> */}
          <div class="box-disclou mt-3"><a href="SecretarialCompliance">23. Secretarial Compliance Report</a></div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 ">
          <div class="box-disclou mt-3"><a href="/NewspaperAdvertisement">24. Newspaper Advertisement</a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/Policy-on-Materiality-of-events-info.pdf" target='_blank'>25. Policy for determination of materiality of events or
              information</a></div>
          <div class="box-disclou mt-3"><a target='_blank' href="/assets/pdf/Disclosures/26.Details of KMP for determining materiality of event or information.pdf">26. Details of KMP for determining materiality of event or
              information</a></div>
          <div class="box-disclou mt-3"><a href="/CorporateAnnouncements">27. Disclosures under Regulation 30</a>
          </div>
          <div class="box-disclou mt-3"><a href="/Statement">28. Statement of deviation or variation</a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Policies/Dividend-Distribution-Policy.pdf">29. Dividend Policy </a></div>
          <div class="box-disclou mt-3"><a href="/AnnualReturnundersection92">30. Annual Return under section 92</a></div>
          <div class="box-disclou mt-3"><a href="/assets/pdf/Disclosures/31. Certificate as per Regulation 46 (3) of Securities and Exchange Board of India (Listing Obligations and Disclosure Requirements) Regulations 2015.pdf" target='_blank'>31. Certificate as per Regulation 46(3)</a></div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Disclosures
