import React from 'react'

const Madhyapradesh = () => {
  return (
    <>
    <section>
      <img src="../assets/img/madhya-pradesh-sub.png" class="w-100 height-215" alt="" />
    </section>
    <section class="mt-5 animation-element slide-up testimonial in-view">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 maha">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <b>NHDC Limited Indira Sagar Power Station</b>
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Cleaning and Upkeeping of roads, view point, sewerage system, project hospital, collection and disposal of garbage from colony area of NHDC at Indira Sagar Power Station, Narmada Nagar, Distt. Khandwa (M.P)
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>NHDC Limited Indira Sagar Power Station: Providing Services for civil maintenance work and cleaning of Roads & Drains atPowerHouseSwitchyard&Damareaatlndira Sagar Power Station, Narmada Nagar, Distt. Khandwa (M'P)' for
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 display-none">
            <img src="../assets/img/new-img-4.png" class="w-75" alt="" />
          </div>
        </div>
      </div>  
    </section>
    </>
  )
}

export default Madhyapradesh
