import axios from "axios";

const apiService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});
const getAccessToken = () => {
    return localStorage.getItem('access_token');
};

const admin_get_data = (endpoint) => {
    const token = getAccessToken();
    return apiService.get(endpoint, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

const admin_post_formData = (endpoint, formData, loggedData) => {
    return apiService.post(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${loggedData?.token}`,
        },
    });
};
const admin_post_UpdateformData = (endpoint, formData, loggedData) => {
    return apiService.put(endpoint, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${loggedData?.token}`,
        },
    });
};




// Function to delete data from an endpoint with admin privileges
const admin_delete_data = (endpoint) => {
    const token = getAccessToken();
    return apiService.delete(endpoint, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
};

export{admin_get_data,admin_delete_data,admin_post_formData,admin_post_UpdateformData}
 











