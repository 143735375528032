import React from 'react'
import { VectorMap } from "react-jvectormap";
const OngoingProjects = () => {
    const mapData = {
        'IN-RJ': 1000, // Rajasthan
        'IN-MP': 800,   // Madhya Pradesh
        'IN-GJ': 700,   // Gujarat
        'IN-MH': 1200,  // Maharashtra (Custom color)
        'IN-CT': 500,   // Chhattisgarh (formerly IN-DL), color changed to #bedf8b
        // 'IN-KL': 500    // Kerala
        'IN-JK': 600    // Jammu and Kashmir
    };

    // Function to handle showing data on hover
    const handleShowData = (e, el, code) => {
        // Add logic to show data on hover if needed
    };

    // Function to handle region click
    const handleRegionClick = (e, code) => {
        console.log(code); // Log the country code on region click
    };
    return (
        <>
            <section class="breadcrum-ongoing">
                <div class="container-fluid">
                    <div class="row">
                        <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                            <h1 class="text-white"> Projects
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 animation-element slide-up testimonial in-view">
                <div className="container mt-4">
                    <div className="row justify-content-center align-items-start">
                        <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
                            <div className='card-project p-2 mb-4'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <img src="../assets/img/project/project-1.png" className='w-100' alt="" />
                                    </div>
                                    <div className='col-9'>
                                        <h2> MAHARASHTRA</h2>
                                        <p className='mb-1'>Work for Drain Cleaning, Garbage Lifting Garbage of Sweeping and Night shift..</p>
                                        <a href='/Maharashtra' className='text-decoration-none green-color'>Read more <i class="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='card-project p-2 mb-4'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <img src="../assets/img/project/project-2.png" className='w-100' alt="" />
                                    </div>
                                    <div className='col-9'>
                                        <h2>GUJARAT</h2>
                                        <p className='mb-1'>Door To Door Refuse Garbage Collection And Transportation Upto Dumping Site..</p>
                                        <a href='/Gujarat' className='text-decoration-none green-color'>Read more <i class="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='card-project p-2 mb-4'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <img src="../assets/img/project/project-3.png" className='w-100' alt="" />
                                    </div>
                                    <div className='col-9'>
                                        <h2>RAJASTHAN</h2>
                                        <p className='mb-1'>Providing Vehicles (auto-tippers) for Door-to-Door collection of segregated...</p>
                                        <a href='/Rajasthan' className='text-decoration-none green-color'>Read more <i class="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='card-project p-2 mb-4'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <img src="../assets/img/project/project-4.png" className='w-100' alt="" />
                                    </div>
                                    <div className='col-9'>
                                        <h2>MADHYAPRADESH</h2>
                                        <p className='mb-1'>Cleaning and Upkeeping of roads, view point, sewerage system...</p>
                                        <a href='/Madhyapradesh' className='text-decoration-none green-color'>Read more <i class="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='card-project p-2 mb-4'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <img src="../assets/img/project/project-5.png" className='w-100' alt="" />
                                    </div>
                                    <div className='col-9'>
                                        <h2>CHHATTISGARH</h2>
                                        <p className='mb-1'>Working in all five zone, 52.5 wards of Nagar Palik Nigam Bhilai for Door-to-Door..</p>
                                        <a href='/Chhattisgarh' className='text-decoration-none green-color'>Read more <i class="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='card-project p-2 mb-4'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <img src="../assets/img/project/project-5.png" className='w-100' alt="" />
                                    </div>
                                    <div className='col-9'>
                                        <h2>JAMMU AND KASHMIR</h2>
                                        <p className='mb-1'>Project of Collection, transportation, processing, and disposal of the Solid Waste ..</p>
                                        <a href='/Jammu' className='text-decoration-none green-color'>Read more <i class="fas fa-arrow-right ms-2"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 '>
                            <div className='d-flex justify-content-center'>
                                <VectorMap
                                    map={"in_mill"}
                                    backgroundColor="transparent"
                                    focusOn={{
                                        x: 0.5,
                                        y: 0.5,
                                        scale: 0,
                                        animate: false
                                    }}
                                    zoomOnScroll={true}
                                    containerStyle={{
                                        width: "100%",
                                        height: "600px"
                                    }}
                                    onRegionClick={handleRegionClick}
                                    onRegionTipShow={handleShowData}
                                    containerClassName="map"
                                    regionStyle={{
                                        initial: {
                                            fill: "#bdb6b6",
                                            "fill-opacity": 0.9,
                                            stroke: "#000000",
                                            "stroke-width": 0,
                                            "stroke-opacity": 0
                                        },
                                        hover: {
                                            "fill-opacity": 0.8,
                                            cursor: "pointer"
                                        },
                                        selected: {
                                            fill: "#2938bc" // onclick colour of state
                                        },
                                    }}
                                    regionsSelectable={false}
                                    series={{
                                        regions: [
                                            {
                                                values: mapData,
                                                scale: ['#bedf8b', '#38481e'], // Color range
                                                normalizeFunction: "polynomial"
                                            }
                                        ]
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OngoingProjects
