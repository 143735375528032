import React from 'react'

const Yogeshgupta = () => {
  return (
    <>
      <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container mt-4">
      <div class="row justify-content-center align-items-start">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 animation-element slide-up testimonial in-view">
          <img src="../assets/img/Yogeshgupta.png" classNameName='w-100' alt="" />
          <h1 class="blue-color text-center mt-4">
            Shri Yogesh Kumar Gupta
          </h1>
          <h3 class="text-center">Independent Director</h3>
          <h5 class="text-justify text-muted mt-3">He is an Independent Director. He has completed his Secondary
            Education. He has approximately 10 years of business work experience.</h5>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Yogeshgupta
