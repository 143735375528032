import React from 'react'

const Career = () => {
    return (
        <>
            <section class="breadcrum-career">
                <div class="container-fluid">
                    <div class="row">
                        <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                            <h1 class="text-white">Career
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="mt-5 animation-element slide-up testimonial in-view">
                <div class="container">
                    {/* <div class="box-leader p-3">
                        <h4 class="color-blue blogs_titles">Current Openings :</h4>
                        <p class="mb-0">1. Required Operation Managers /Field Supervisors (Only Male Candidate) in Urban Enviro Waste Management Limited.</p>
                        <ul>
                            <li>Having experience of 3 years or more in MSW</li>
                            <li>Diploma in Solid waste Management</li>
                            <li>Language known- English, Hindi and respective State Language </li>
                        </ul>
                        <p className='mb-1'><b>Location:</b> Jaipur (Rajasthan) ,Vadodara (Gujarat),Ankleshwar (Gujarat)</p>
                    </div>
                    <div class="box-leader p-3 mt-3">

                        <p class="mb-0">2.Required Manager (IEC Activity) / Specialist (IEC Activity)</p>
                        <ul>
                            <li>Any Graduate/post graduate having Computer knowledge (MS-Office)</li>
                            <li>Experience in development and design of IEC materials and very good communication, teamwork and interpersonal skills</li>
                            <li>Responsible for: Development, planning, organisation, implementation and monitoring of all IEC activities for Gujarat state</li>
                            <li>Language known- English and Hindi</li>
                            <li>Start date: Immediately</li>
                        </ul>
                        <p className='mb-1'><b>Location:</b>Vadodara (Gujarat), Jaipur (Rajasthan)</p>


                    </div> */}
                    <p class="text-justify mt-3">URBAN is an organization where we focus on development of employees as much as on
                        development of innovative solutions since, we firmly believe that the growth of an organization is directly
                        proportional to the growth of its employees.
                        <br />
                        <br />
                        Today URBAN is growing at a rapid phase. This growth necessitates greater investment in talent. At URBAN, we are
                        looking for the enthusiastic, dynamic individuals who would not only take the company to newer heights but take
                        charge of its growth and values as future leaders.
                        <br />
                        <br />
                        By joining URBAN, you will become part of a team, with the potential to become part of a truly nation and
                        worldwide service provider of waste management Industry. People with strong values of honesty, passion to excel
                        and a zest for doing something better and the enthusiasm for continuous development are welcome to be a part of
                        the URBAN family.
                        <br />
                        <br />
                        We sincerely thank all candidates who choose to apply, however, depending on the requirement and only those
                        selected for a further interview will be contacted.
                        <br />
                        Recent job openings are available at Current Openings
                    </p>
                    <div class="box-leader-box p-2">
                        <h4 class="color-blue blogs_titles">Send in your resume to:</h4>
                        <p class="mb-0">Urban Enviro Waste Management Limited, 26, Matey Square, BPCL petrol pump, Gopal Nagar Road,
                            Nagpur-440022</p>
                        <p className='mb-1'>Email: <a href="mailto:info@urbanenv.in" class=" text-dark">info@urbanenv.in</a></p>
                        <p>Contact no.: <a href="tel:9978565555" class=" text-dark"> 9978565555</a></p>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Career
