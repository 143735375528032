import React from 'react'

const KamleshSharma = () => {
  return (
    <>
          <section class="mt-5 animation-element slide-up testimonial in-view">
      <div class="container mt-4">
        <div class="row justify-content-center align-items-start">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 animation-element slide-up testimonial in-view">
            <img src="../assets/img/chairman-message-new.png" className='w-100' alt="" />
            <h1 class="blue-color text-center mt-4">Shri Kamlesh Sharma</h1>
            <h3 class="text-center">Chairman & Managing Director</h3>
            <h5 class="text-justify text-muted mt-3">He is a Managing Director of the Company. He holds a degree in Bachelor of Arts from University of Rajasthan. He possesses approximately with 25 years of phenomenal experience in planning and formulating the overall business and commercial strategy and developing business of Municipal Solid Waste Management projects.</h5>
         </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default KamleshSharma
