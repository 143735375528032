import axios from 'axios';
import React, { useState } from 'react';
import { BASEURL } from '../Constants';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

const ContactUs = () => {
    const initialInputs = {
        firstname: '',
        lastname: '',
        email: '',
        phone_number: '',
        massage: '',
        is_contact: true
    };

    const [inputs, setInputs] = useState(initialInputs);
    const [loading, setLoading] = useState(false);

    const submitContact = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const resp = await axios.post(`${BASEURL}/contact_us`, inputs);
            if (resp.data.code === 200) {
                setInputs(initialInputs); // Reset the form inputs
                toast.success("Thank you for contacting us we will get back to you soon...");
              }
            setInputs(initialInputs); // Reset the form inputs
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((prevInputs) => {
            if (name === 'phone_number') {
                // Apply numeric validation for phone_number
                if (/^\d*$/.test(value) && value.length <= 10) {
                    return {
                        ...prevInputs,
                        [name]: value,
                    };
                } else {
                    return prevInputs; // Do not update state if validation fails
                }
            } else {
                // No validation for other inputs
                return {
                    ...prevInputs,
                    [name]: value,
                };
            }
        });
    };

    return (
        <>
            <section className="breadcrum-contact">
                <div className="container-fluid">
                    <div className="row">
                        <div className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                            <h1 className="text-white">Contact Us</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 animation-element slide-up testimonial in-view">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                            <h3 className="green-color">Contact With Us</h3>
                            <p className="font-18">We provide a rapid response waste removal & recycle service. Available to our business customers 24 hours a day.</p>
                            <div className="row mt-2">
                                <div className="col-1">
                                    <i className="fas fa-map-marker-alt green-color font-25"></i>
                                </div>
                                <div className="col-11">
                                    <h5><b>Location</b></h5>
                                    <p>26, Matey Square, BPCL Petrol pump, Nagpur-440022, Maharashtra</p>
                                </div>
                            </div>
                            <div className="row  mt-2">
                                <div className="col-1">
                                    <i className="fas fa-envelope green-color font-25"></i>
                                </div>
                                <div className="col-11">
                                    <h5><b>Email</b></h5>
                                    <p><a href="mailto:info@urbanenv.in" className=" text-dark text-decoration-none">info@urbanenv.in</a></p>
                                </div>
                            </div>
                            <div className="row  mt-2">
                                <div className="col-1">
                                    <i className="fas fa-phone-alt green-color font-25"></i>
                                </div>
                                <div className="col-11">
                                    <h5><b>Phone</b></h5>
                                    <p><a href="tel:+7122996029" className="text-dark text-decoration-none">91-7122996029</a></p>
                                </div>
                            </div>
                            <div className="row  mt-2">
                                <div className="col-1">
                                    <i className="fas fa-user-clock green-color font-25"></i>
                                </div>
                                <div className="col-11">
                                    <h5><b>Office Hours</b></h5>
                                    <p>Mon - Sat: 9.00am to 8.00pm (Sun: Closed)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-lg-8 col-sm-12 col-12">
                            <form onSubmit={submitContact}>
                                <div className="box-contact">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                                            <input
                                                type="text"
                                                className="form-control p-3"
                                                placeholder="First Name *"
                                                name="firstname"
                                                value={inputs.firstname}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
                                            <input
                                                type="text"
                                                className="form-control p-3"
                                                placeholder="Last Name *"
                                                name="lastname"
                                                value={inputs.lastname}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                        <input
                                            type="email"
                                            className="form-control p-3"
                                            placeholder="Email *"
                                            name="email"
                                            value={inputs.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                        <input
                                            type="tel"
                                            id="phone"
                                            name="phone_number"
                                            pattern="[0-9]{10}"
                                            maxLength={10}
                                            className="form-control p-3"
                                            placeholder="Phone Number*"
                                            value={inputs.phone_number}
                                            onChange={handleChange}
                                            required
                                            title="Please enter exactly 10 digits"
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="3"
                                            placeholder="Message *"
                                            name="massage"
                                            value={inputs.massage}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center">

                                        <button href="" className="d-flex btn  contact_us " type="submit">
                                            {loading && (
                                                <div className="spinner-overlay">
                                                    <Spinner animation="border" size='sm' role="status" className="spinner">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            )}
                                            {loading ? ' please wait...' : 'Send Message'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 mb-0">
                <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.785001874558!2d79.05320965218486!3d21.121136054353528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c1cc48b98b7b%3A0x8b764d5aa1afc49a!2sUrban%20Enviro%20Ltd.!5e0!3m2!1sen!2sin!4v1721385795707!5m2!1sen!2sin"
                    className="map-contact"
                    style={{ border: "0" }} // Adjust width and height as needed
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </section>

        </>
    );
};

export default ContactUs;
