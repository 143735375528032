import React from 'react'

const Chairman = () => {
  return (
    <>
          <section className="breadcrum">
        <div className="container-fluid">
            <div className="row">
                <div 
                    className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                    <h1 className="text-white">Chairman’s Message
                    </h1>
                </div>
            </div>
        </div>
    </section>
    <section className="mt-5 animation-element slide-up testimonial in-view">
      <div className="container mt-4">
        <div className="row justify-content-center align-items-start">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 animation-element slide-up testimonial in-view">
            <img src="../assets/img/chairman-message-new.png" className='w-100 margin-auto width-75 d-flex' alt="" />
            <h3 className="text-center mt-3">Shri. Kamlesh Sharma</h3>
            <h5 className="text-center">Chairman & Managing Director</h5>
          </div>
           <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 animation-element slide-up testimonial in-view">
                <div className="card px-2 py-2 back_Color">
              <h6 className=" white-color px-3">“Efficient waste management isn’t just our responsibility; it’s our commitment
                to crafting sustainable communities for a better tomorrow.”</h6>
            </div>
            <p className="mt-4 text-justify">
              Welcome to Urban Enviro Waste Management Limited, where our commitment to excellence in solid waste
              management drives our every endeavour. As Chairman, I am proud to lead a team dedicated to transforming
              waste into opportunity, safeguarding the environment, and shaping sustainable communities.</p>
            <p className="text-justify">At URBAN, we understand the critical role effective waste management plays in preserving our planet. Our
              innovative solutions and cutting-edge technologies ensure responsible handling and disposal of municipal
              solid waste, minimising environmental impact while maximising resource recovery.</p>
            <p className="text-justify">We are more than a waste management company; we are stewards of a cleaner, greener future. Our unwavering
              dedication to operational excellence and sustainability drives us to constantly evolve and innovate.</p>
            <p className="text-justify">
              By fostering valuable partnerships, embracing technological advancements, and prioritising environmental
              stewardship, we aim to set new standards in waste management, creating a positive ripple effect on
              communities and industries.
            </p>
            <p className="text-justify">
              I invite you to explore our website, learn about our initiatives, and join us in our mission to
              revolutionise the way we manage waste for a better and more sustainable world.
            </p>
            <p className="text-justify">Thank you for your support and commitment to a cleaner future.</p>
         </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Chairman
