import React from 'react'

const SheelaSharma = () => {
  return (
    <>
       <section class="mt-5 animation-element slide-up testimonial in-view">
      <div class="container mt-4">
        <div class="row justify-content-center align-items-start">
          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 animation-element slide-up testimonial in-view">
            <img src="../assets/img/SheelaSharma.png" classNameName='w-100' alt="" />
            <h1 class="blue-color text-center mt-4">Smt. Sheela Suresh Sharma</h1>
            <h3 class="text-center">Non-Executive Director</h3>
            <h5 class="text-justify text-muted mt-3">Smt. Sheela Sharma is a Non-Executive Women Director. She has completed her Secondary Education. She is responsible for Administration and overall supervision of our business in the company. </h5>
         </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default SheelaSharma
