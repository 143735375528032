import React from 'react'

const Services = () => {
  return (
    <>
      <section className="breadcrum">
    <div className="container-fluid">
      <div className="row">
      <div className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
          <h1 className="text-white">Services
          </h1>
                        </div>
      </div>
    </div>
  </section>
  <section className="mt-5 animation-element slide-up testimonial in-view">
    <div className="container">
        <div className="row mb-3 d-flex justify-content-center align-items-center animation-element slide-up testimonial in-view">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <img src="../assets/img/service/door-to-door.png" className="w-100 height-400 height-auto mb-4" alt=""/>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <h1 className="green-color">DOOR TO DOOR COLLECTION</h1>
            <p className="text-justify">We are the best garbage door to door pickup company in India, Here we provides our services to residential sector. We had gained a solid understanding of the mechanics of how residential groups can be guided toward building effective residential waste management solutions over the past ten years. All parties involved, including Resident Welfare Associations, property managers, resident volunteers, housekeeping teams, and maintenance personnel, must work closely together. </p>
          </div>
        </div>
        <div className="row mb-3 d-flex justify-content-center align-items-center animation-element slide-up testimonial in-view">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <img src="../assets/img/service/road-sweeping.jpg" className="w-100 height-400 height-auto mb-4" alt=""/>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <h1 className="green-color">ROAD SWEEPING/CLEANING</h1>
            <p className="text-justify">
              Road sweeping or cleaning refers to the process of removing debris, dirt, and other unwanted materials from roads and streets to maintain cleanliness, safety, and aesthetics. This task is typically carried out by using specialized equipment such as street sweepers, vacuum trucks, or manual tools like brooms and shovels. Regular road sweeping helps prevent debris buildup, reduces pollution, improves air quality, and enhances the overall appearance of urban areas. </p>
          </div>
        </div>
        <div className="row mb-3 d-flex justify-content-center align-items-center animation-element slide-up testimonial in-view">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <img src="../assets/img/service/segregation.jpeg" className="w-100 height-400 mb-4 height-auto" alt=""/>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <h1 className="green-color">SEGREGATION</h1>
            <p className="text-justify">
              Solid waste segregation is an important step in effective waste management. It helps in recycling and proper disposal of waste, reducing environmental pollution and promoting sustainability. By separating waste into different categories like recyclables, organic waste, and hazardous materials, we enable more efficient recycling and proper disposal methods.</p>
          </div>
        </div>
        <div className="row mb-3 d-flex justify-content-center align-items-center animation-element slide-up testimonial in-view">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <img src="../assets/img/service/TRANSPORATION-1.png" className="w-100 height-400 height-auto mb-4" alt=""/>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <h1 className="green-color">TRANSPORATION</h1>
            <p className="text-justify">
              Transportation of solid waste to a dumping yard is crucial for maintaining cleanliness and environmental health. This service ensures us proper disposal of waste, preventing pollution and promoting sanitation. It involves collecting waste from various sources, and transporting it safely to designated dumping yards or landfills.
            <br/>
            The company offers such services and plays a significant role in waste management and environmental sustainability. We employ specialized vehicles and trained personnel to handle different types of waste efficiently and safely. </p>
          </div>
        </div>
        <div className="row mb-3 d-flex justify-content-center align-items-center animation-element slide-up testimonial in-view">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <img src="../assets/img/service/processing.jpg" className="w-100 height-400 mb-4 height-auto" alt=""/>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <h1 className="green-color">PROCESSING</h1>
            <p className="text-justify">
           
Processing of solid municipal waste is a crucial service we offer for managing waste effectively and minimizing its impact on the environment. We offer various methods for waste processing, such as sorting, recycling, composting.
</p>
          </div>
        </div>
        <div className="row mb-3 d-flex justify-content-center align-items-center animation-element slide-up testimonial in-view">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <img src="../assets/img/service/mining.jpeg" className="w-100 height-400 mb-2 height-auto " alt=""/>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <h1 className="green-color">BIO MINING OF LEGACY WASTE</h1>
            <p className="text-justify">
              The scientific process of excavation, treatment, segregation and gainful utilisation of aged municipal solid waste or legacy waste is called Biomining.<br/>
              Biomining is an environmentally friendly technique to separate soil and recyclables like plastic, metal, paper, textiles, Construction & Demolition waste materials and other solid materials from legacy waste. we provide a reliable solution to complex environmental issues and uses environmentally friendly techniques for materials sorting and segregation for further use, recycling, or filling low-lying areas.</p>
          </div>
        </div>
    </div>
  </section>
  <section className="mt-5 animation-element slide-up testimonial in-view">
    <div className="container">
      <h2 className="green-color">Why Solid Waste Management is so important?
      </h2>
  
      <div className="row mt-3">
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
          <div className="card-light-bg1 p-3">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-3">
                <img src="../assets/img/service/environmental.png" className="w-100" alt=""/>
              </div>
            </div>
            <h4 className="mt-2 text-white">
              Environmental Protection
            </h4>
           <p className="text-white"> Improper disposal of solid waste can lead to pollution of land, water, and air. This pollution can harm ecosystems, wildlife, and human health. Effective waste management helps minimize these negative environmental impacts</p> 
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
          <div className="card-light-bg2 p-3">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-3">
                <img src="../assets/img/service/social-services.png" className="w-100" alt=""/>
              </div>
            </div>
            <h4 className="mt-2 text-white">
              Public<br/>Health
            </h4>
           <p className="text-white"> Improperly managed solid waste can harbour disease-causing organisms and attract pests such as rats and insects. This can lead to the spread of diseases among humans and animals. Proper waste management practices help protect public health </p> 
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
          <div className="card-light-bg3 p-3">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-3">
                <img src="../assets/img/service/resource.png" className="w-100" alt=""/>
              </div>
            </div>
            <h4 className="mt-2  text-white">
              Resource Conservation
            </h4>
           <p className=" text-white"> Many materials found in solid waste, such as metals, plastics, and paper, can be recycled and reused. Effective waste management includes strategies to recover and recycle these materials, reducing the need for raw materials</p> 
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-3">
          <div className="card-light-bg4 p-3">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-3">
                <img src="../assets/img/service/energy-saving.png" className="w-100" alt=""/>
              </div>
            </div>
            <h4 className="mt-2  text-white">
              Energy <br/>Recovery
            </h4>
           <p className=" text-white"> Some types of solid waste, such as organic waste, can be converted into energy through processes like composting or anaerobic digestion. This helps reduce the reliance on fossil fuels for energy generation and contributes to sustainable energy production.</p> 
          </div>
        </div>
      </div>
      </div>
    </section>
    </>
  )
}

export default Services
